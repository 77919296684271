import React from 'react';
import '../css/add.css';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

class ContactForm extends React.Component {
	state = {
		name: '',
		email: '',
		about: '',
		success: false,
		error: false
	};
	encode = (data) => {
		return Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: this.encode({
				'form-name': form.getAttribute('name'),
				...this.state
			})
		})
			.then(() => {
				this.setState({ success: true });
			})
			.catch(() => {
				this.setState({ error: true });
			});
	};

	handleChange = ({ target: { name, value } }) => {
		this.setState({
			[name]: value
		});
	};

	success = () => (
		<div className="bg-green-lightest border-l-4 border-green text-green-dark p-6 mt-6" role="alert">
			<p className="font-bold text-2xl">The information has been sent successfully</p>
			<br />
			<p className="text-xl">We'll be in touch very soon.</p>
		</div>
	);

	error = () => (
		<div className="bg-red-lightest border-l-4 border-red text-red-dark p-6 mt-6" role="alert">
			<p className="font-bold text-2xl">Oops, something unexpected happened</p>
			<br />
			<p className="text-xl">
				We have trouble to get your info, please send email to{' '}
				<span className="font-bold"> info@unbreakable.studio</span>
			</p>
		</div>
	);

	render() {
		let { name, email, about, success, error } = this.state;
		if (error) return this.error();
		if (success) return this.success();
		let { black } = this.props;
		return (
			<form
				name="contact"
				method="post"
				className="w-full block pt-3"
				onSubmit={this.handleSubmit}
				autoComplete="off"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
				// action="/contact/"
			>
				<input type="hidden" name="form-name" value="contact" />
				<p hidden>
					<label>
						Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
					</label>
				</p>
				<label className="block text-1xl uppercase mt-4 font-semibold pt-3" htmlFor="name">
					Name
					<input
						className={`${black
							? 'bg-black-us border border-green-us-light  text-white '
							: 'bg-grey-lighter text-black'} w-full blocl text-2xl p-2 mt-2 font-thin tracking-wide font-li`}
						type="text"
						autoCapitalize="none"
						autoCorrect="false"
						spellCheck="false"
						name="name"
						id="name"
						value={name}
						onChange={this.handleChange}
						required
					/>
				</label>
				<label className="block text-1xl uppercase mt-4 font-semibold pt-3" htmlFor="email">
					Email
					<input
						// className="w-full blocl text-2xl p-2 mt-2 bg-grey-lighter font-thin tracking-wide font-li"
						className={`${black
							? 'bg-black-us border border-green-us-light  text-white'
							: 'bg-grey-lighter text-black'} w-full blocl text-2xl p-2 mt-2 font-thin tracking-wide font-li`}
						type="email"
						autoCapitalize="none"
						autoCorrect="false"
						spellCheck="false"
						name="email"
						id="email"
						value={email}
						onChange={this.handleChange}
						required
					/>
				</label>
				<label className="block text-1xl uppercase mt-4 font-semibold pt-3" htmlFor="about">
					About the project
					<textarea
						className={`${black
							? 'bg-black-us border border-green-us-light  text-white'
							: 'bg-grey-lighter text-black'} w-full blocl text-2xl p-2 mt-2 font-thin tracking-wide h-64`}
						name="about"
						autoCapitalize="none"
						autoCorrect="false"
						spellCheck="false"
						id="about"
						value={about}
						onChange={this.handleChange}
						required
					/>
				</label>
				<div className="mt-6">
					<button
						type="submit"
						className={`uppercase tracking-wide ${black
							? 'bg-white text-black hover:text-grey'
							: 'bg-black-us text-white'} hover:bg-grey-darkest p-5 font-semibold`}
					>
						submit
					</button>
				</div>
			</form>
		);
	}
}

class ContactPage extends React.Component {
	render() {
		let fluid = this.props.data.map.childImageSharp.fluid;
		return (
			<Layout>
				<div className="points">
					<div className="flex justify-center items-center container text-black mx-auto p-6 text-left pb-40 pt-24 md:pt-18 sm:pt-18">
						<div className="max-w-lg">
							<div className="pb-5">
								<div className="pb-5">
									<svg height="7" width="40">
										<line
											x1="0"
											y1="0"
											x2="100"
											y2="0"
											style={{ stroke: '#1CF5BA', strokeWidth: '6' }}
										/>
									</svg>{' '}
									<span className="font-medium tracking-wide ml-2 uppercase">contact us</span>
								</div>
								<h1 className="xl:text-7xl lg:text-7xl md:text-7xl text-6xl pb-5">Let's talk</h1>
								<h2 className="text-4xl font-thin">
									Got a project? Give us a shout below and we'll be in touch.
								</h2>
								<ContactForm />
								<br />
								<br />
								<div className="bg-grey-lighter p-4">
									<span className="text-2xl">
										<label className="block text-base uppercase mt-4 font-semibold pt-3 mb-3">
											Contact
										</label>
										<span className="
										font-bold 
										bg-green-us-light
										text-grey-darkest 
										p-1
										">
											info@unbreakable.studio
										</span>
									</span>
									<div className="w-full">
										<label className="block text-base uppercase mt-4 font-semibold pt-3 mb-3">
											Location
										</label>
										<Img fluid={fluid} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default ContactPage;
export { ContactForm };

export const query = graphql`
	{
		map: file(relativePath: { eq: "map.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
	}
`;
